<template>
  <span>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page" backgrounds
      :page-sizes="[10, 20, 50, 100]" :page-size="limit" layout="total,sizes, prev, pager, next,jumper" :total="total">
    </el-pagination>
  </span>
</template>

<script>
  export default {
    name: 'TablePage',
    props: {
      page: {
        default: 1,
        type: Number
      },
      limit: {
        default: 10,
        type: Number
      },
      total: {
        default: 0,
        type: Number
      }
    },
    data() {
      return {
      }
    },
    methods: {
      handleSizeChange(val) {
        this.$emit("search-table",{page:this.page,limit:val})
      },
      handleCurrentChange(val) {
         this.$emit("search-table",{page:val,limit:this.limit})
      }
    }
  }
</script>

<style lang="less">
  .el-pagination {
    // background: #f6f6f6;
    margin-top: 10px;
    padding: 2px 10px;

    .el-select--mini {

      .el-input__inner {
        height: 24px;
        line-height: 24px;
      }

      .el-icon-arrow-up {
        line-height: 24px;
      }
    }

    .el-pagination__jump {
      margin-left: 0;
      margin-right: 5px;

      .el-input__inner {
        height: 24px;
        line-height: 24px;
      }
    }

    // .el-pager li,
    // .btn-prev,
    // .btn-next {
    //   background: #f6f6f6 !important;
    // }
  }
</style>
